<template>
  <q-page class="dailyReportPage">
    <DailyReportExternalToolBar v-if="!isPrintMode" />

    <transition
      enter-active-class="animated animate__fadeIn"
      leave-active-class="animated animate__fadeOut"
      mode="out-in"
      :duration="140"
    >
      <q-scroll-area
        v-if="!loading"
        :visible="!isPrintMode"
        :style="{
          height: isPrintMode ? '0px' : 'calc(100vh - 183px)',
          minWidth: '100%',
        }"
      >
        <teleport to="#print" :disabled="!isPrintMode">
          <h4 class="q-pl-md" style="margin-bottom: 0" v-if="isPrintMode">
            Dygnsrapport extern
          </h4>
          <h6 class="q-pl-md" style="margin-top: 0" v-if="isPrintMode">
            {{ date }}
          </h6>
          <div class="row" :style="{ display: isPrintMode ? 'block' : 'flex' }">
            <div class="col-12 col-xl-6">
              <DailyReportExternalTrafficTable />
            </div>
            <div class="col-12 col-xl-6">
              <DailyReportExternalVehicleStatusTable
                :summary="withdrawalDashboardData.summary"
                v-if="can('report.dailyReportExternal.section.vehicleStatus')"
              />
            </div>
          </div>
          <DailyReportExternalDeviationTable
            v-for="(item, $i) in filteredDeviations"
            :key="$i"
            :title="item.name"
            :data="item.deviations"
          />
        </teleport>
      </q-scroll-area>
      <div
        v-else
        style="height: calc(100vh - 183px)"
        class="full-width items-center justify-center flex"
      >
        <q-spinner color="secondary" size="10em" :thickness="1" />
      </div>
    </transition>
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import DailyReportExternalToolBar from '@/components/daily-report-external/DailyReportExternalToolBar.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import DailyReportExternalTrafficTable from '@/components/daily-report-external/DailyReportExternalTrafficTable.vue'
import DailyReportExternalVehicleStatusTable from '@/components/daily-report-external/DailyReportExternalVehicleStatusTable.vue'
import DailyReportExternalDeviationTable from '@/components/daily-report-external/DailyReportExternalDeviationTable.vue'
import { useVehicle } from '@/composable/useVehicle'
import { useProfile } from '@/composable/useProfile'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { DailyReportDeviation } from '@/types/daily-report-deviation'

export default defineComponent({
  name: 'DailyReportExternal',

  components: {
    DailyReportExternalToolBar,
    DailyReportExternalTrafficTable,
    DailyReportExternalVehicleStatusTable,
    DailyReportExternalDeviationTable,
  },

  setup() {
    const { can } = useProfile()
    const {
      loading: dailyReportLoadingState,
      data,
      date,
      isPrintMode,
      selectedFilterEventType,
    } = useDailyReport()
    const { loading: loadingStateVehicle, fetchAll: fetchAllVehicle } =
      useVehicle()
    const {
      loading: withdrawalDashboardLoading,
      data: withdrawalDashboardData,
    } = useWithdrawalDashboard()

    fetchAllVehicle()

    const loading = computed(() => {
      return (
        dailyReportLoadingState.value.traffic.getAll ||
        dailyReportLoadingState.value.vehicleStatus.getAll ||
        dailyReportLoadingState.value.deviation.getAll ||
        dailyReportLoadingState.value.event.getAll ||
        loadingStateVehicle.value.getAll ||
        withdrawalDashboardLoading.value.fetchSummary
      )
    })

    const trackHasComment = (trackName: string) => {
      return data.value.event.some(
        (x) =>
          x.luppTracks.some((y) => y.description === trackName) && x.description
      )
    }

    const filterDeviation = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          trackHasComment(deviation.track) ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filterDeviation2 = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filteredDeviations = computed(() =>
      data.value.deviation
        .filter((deviation) => {
          return (
            deviation.deviations.filter(filterDeviation).length ||
            trackHasComment(deviation.name)
          )
        })
        .map((x) => ({
          ...x,
          deviations: x.deviations.filter(filterDeviation2),
        }))
    )

    return {
      can,
      date,
      data,
      loading,
      isPrintMode,
      withdrawalDashboardData,
      filteredDeviations,
    }
  },
})
</script>

<style lang="scss">
.dailyReportPrintMode {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 9999;
  overflow-y: scroll;
  overflow-x: scroll;
}

.printMode .dailyReportPage {
  min-height: 0 !important;
}

.printMode .q-scrollarea__thumb {
  opacity: 0 !important;
}

.printLandscape {
  @page {
    size: landscape;
  }
}
</style>
