
import { computed, defineComponent } from 'vue'
import DailyReportExternalToolBar from '@/components/daily-report-external/DailyReportExternalToolBar.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import DailyReportExternalTrafficTable from '@/components/daily-report-external/DailyReportExternalTrafficTable.vue'
import DailyReportExternalVehicleStatusTable from '@/components/daily-report-external/DailyReportExternalVehicleStatusTable.vue'
import DailyReportExternalDeviationTable from '@/components/daily-report-external/DailyReportExternalDeviationTable.vue'
import { useVehicle } from '@/composable/useVehicle'
import { useProfile } from '@/composable/useProfile'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { DailyReportDeviation } from '@/types/daily-report-deviation'

export default defineComponent({
  name: 'DailyReportExternal',

  components: {
    DailyReportExternalToolBar,
    DailyReportExternalTrafficTable,
    DailyReportExternalVehicleStatusTable,
    DailyReportExternalDeviationTable,
  },

  setup() {
    const { can } = useProfile()
    const {
      loading: dailyReportLoadingState,
      data,
      date,
      isPrintMode,
      selectedFilterEventType,
    } = useDailyReport()
    const { loading: loadingStateVehicle, fetchAll: fetchAllVehicle } =
      useVehicle()
    const {
      loading: withdrawalDashboardLoading,
      data: withdrawalDashboardData,
    } = useWithdrawalDashboard()

    fetchAllVehicle()

    const loading = computed(() => {
      return (
        dailyReportLoadingState.value.traffic.getAll ||
        dailyReportLoadingState.value.vehicleStatus.getAll ||
        dailyReportLoadingState.value.deviation.getAll ||
        dailyReportLoadingState.value.event.getAll ||
        loadingStateVehicle.value.getAll ||
        withdrawalDashboardLoading.value.fetchSummary
      )
    })

    const trackHasComment = (trackName: string) => {
      return data.value.event.some(
        (x) =>
          x.luppTracks.some((y) => y.description === trackName) && x.description
      )
    }

    const filterDeviation = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          trackHasComment(deviation.track) ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filterDeviation2 = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filteredDeviations = computed(() =>
      data.value.deviation
        .filter((deviation) => {
          return (
            deviation.deviations.filter(filterDeviation).length ||
            trackHasComment(deviation.name)
          )
        })
        .map((x) => ({
          ...x,
          deviations: x.deviations.filter(filterDeviation2),
        }))
    )

    return {
      can,
      date,
      data,
      loading,
      isPrintMode,
      withdrawalDashboardData,
      filteredDeviations,
    }
  },
})
