import { getTracks } from '@/api/lupp/getTracks'
import { LuppTrack } from '@/types/lupp-track'
import { ref, Ref } from 'vue'

interface LoadingState {
  track: {
    getAll: boolean
  }
}

interface DataState {
  track: LuppTrack[]
}

interface UseLupp {
  getAllTracks: () => Promise<LuppTrack[]>
  loading: Ref<LoadingState>
  data: Ref<DataState>
}

const loading = ref<LoadingState>({
  track: {
    getAll: false,
  },
})

const data = ref<DataState>({
  track: [],
})

export function useLupp(): UseLupp {
  function getAllTracks() {
    loading.value.track.getAll = true
    return new Promise<LuppTrack[]>((resolve, reject) => {
      getTracks()
        .then(({ data: luppTrack }) => {
          data.value.track = luppTrack
          resolve(luppTrack)
        })
        .catch(reject)
        .finally(() => {
          loading.value.track.getAll = false
        })
    })
  }

  return {
    getAllTracks,
    data,
    loading,
  }
}
