import { render } from "./DailyReportExternal.vue?vue&type=template&id=8f1ca720"
import script from "./DailyReportExternal.vue?vue&type=script&lang=ts"
export * from "./DailyReportExternal.vue?vue&type=script&lang=ts"

import "./DailyReportExternal.vue?vue&type=style&index=0&id=8f1ca720&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QScrollArea,QSpinner});
