
import { computed, defineComponent, watch } from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { useLupp } from '@/composable/useLupp'
import { isValid, subDays, format } from 'date-fns'
import { DailyReportTrack } from '@/types/daily-report-deviation'
import { useProfile } from '@/composable/useProfile'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { useFilterLocalStorage } from '@/composable/useFilterLocalStorage'

export default defineComponent({
  name: 'DailyReportExternalToolBar',

  components: {
    AppFieldDate,
  },

  setup() {
    const {
      getAllTraffic,
      getAllVehicleStatus,
      getAllDeviation,
      getAllEvent,
      date,
      isPrintMode,
      data,
      selectedFilterEventType,
    } = useDailyReport(format(subDays(new Date(), 1), 'yyyy-MM-dd'))

    const { getAllTracks } = useLupp()
    const { getCurrentProject, currentProject } = useProfile()
    const { fetchSummary } = useWithdrawalDashboard()
    const { getFilterLocalstorage, setFilterLocalstorage } =
      useFilterLocalStorage()
    const transportAuthority =
      getCurrentProject()?.transportAuthority || 'extern'

    watch(
      () => selectedFilterEventType.value,
      (value) => {
        if (!currentProject.value) return
        setFilterLocalstorage(
          { project: currentProject.value.name, view: 'dailyReportExternalV1' },
          {
            selectedEventTypes: value,
          }
        )
      }
    )

    watch(
      date,
      (v) => {
        if (v.length !== 10 || !isValid(new Date(v))) return

        getAllTraffic(v)
        getAllVehicleStatus(v)
        getAllDeviation(v).then((data) => {
          selectedFilterEventType.value = getUniqueDeviationTypes(data).filter(
            (x) => x !== 'Försening'
          )

          if (currentProject.value) {
            const filter = getFilterLocalstorage({
              project: currentProject.value.name,
              view: 'dailyReportExternalV1',
            })

            if (filter.selectedEventTypes !== null) {
              selectedFilterEventType.value = filter.selectedEventTypes
            }
          }
        })
        getAllEvent(v)
        getAllTracks()
        fetchSummary({
          from: v,
          to: v,
          time: '07:00',
        })
      },
      {
        immediate: true,
      }
    )

    function getUniqueDeviationTypes(deviations: DailyReportTrack[]) {
      return [
        ...new Set(deviations.flatMap((x) => x.deviations).map((x) => x.type)),
      ]
    }

    const eventTypes = computed(() => {
      return getUniqueDeviationTypes(data.value.deviation)
    })

    function onPrint() {
      isPrintMode.value = true
      document.body.classList.add('printMode')
      setTimeout(() => {
        window.print()
        setTimeout(() => {
          isPrintMode.value = false
          document.body.classList.remove('printMode')
        }, 100)
      }, 200)
    }

    const filterEvenTypeCount = computed(() => {
      return selectedFilterEventType.value.length
    })

    return {
      date,
      onPrint,
      selectedFilterEventType,
      eventTypes,
      transportAuthority,
      filterEvenTypeCount,
    }
  },
})
